import React from 'react'
import styles from '../UnauthorisedLayout.module.scss'

export const PolicyPage = () => {
  return (
    <section className={styles.docsSection}>
      <h1 className={styles.docsSection__title}>Политика конфиденциальности</h1>
      <div className={styles.docsSection__text}>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую ТОО «A-Gene» (Далее - ТОО) может получить о Пользователе посредством информационно-телекоммуникационной сети «Интернет» через сайт или через мобильное приложение (далее по тексту – Платформа).</div>

      <h3 className={styles.docsSection__subtitle}>Определения терминов</h3>
      <div className={styles.docsSection__text}>1.1. В настоящей Политике конфиденциальности используются следующие термины:</div>
      <div className={styles.docsSection__text}>1.1.1. Администрация платформы – уполномоченные сотрудники на управление платформом, действующие от имени ТОО, которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</div>
      <div className={styles.docsSection__text}>1.1.2. Персональные данные - любая информация, относящаяся прямо или косвенно к определенному или к определяемому физическому лицу (субъекту персональных данных).</div>
      <div className={styles.docsSection__text}>1.1.3. Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</div>
      <div className={styles.docsSection__text}>1.1.4. Конфиденциальность персональных данных - обязательное для соблюдения ТОО или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</div>
      <div className={styles.docsSection__text}>1.1.5. Пользователь – любое лицо, имеющее доступ к платформе и использующее его посредством информационно-телекоммуникационной сети «Интернет».</div>
      <div className={styles.docsSection__text}>1.1.6. Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</div>
      <div className={styles.docsSection__text}>1.1.7. IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</div>

      <h3 className={styles.docsSection__subtitle}>Общие положения</h3>
      <div className={styles.docsSection__text}>2.1. Использование платформы означает согласие Пользователя с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</div>
      <div className={styles.docsSection__text}>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование платформы.</div>
      <div className={styles.docsSection__text}>2.3. Настоящая Политика конфиденциальности применяется к платформе. ТОО не контролирует и не несет ответственность за сайты или иные интернет-ресурсы третьих лиц, на которые Пользователь может перейти по ссылкам, доступным в платформе.</div>
      <div className={styles.docsSection__text}>2.4. Администрация платформы не проверяет достоверность персональных данных, предоставляемых Пользователем.</div>

      <h3 className={styles.docsSection__subtitle}>Предмет политики конфиденциальности</h3>
      <div className={styles.docsSection__text}>3.1. Настоящая Политика конфиденциальности устанавливает обязательства ТОО по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации платформы при регистрации личного кабинета Пользователя или при оформлении покупки тарифного плана без регистрации личного кабинета Пользователя.</div>
      <div className={styles.docsSection__text}>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем добровольно и включают в себя следующую информацию:</div>
      <div className={styles.docsSection__text}>3.2.1. фамилию, имя Пользователя;</div>
      <div className={styles.docsSection__text}>3.2.2. контактный телефон Пользователя;</div>
      <div className={styles.docsSection__text}>3.2.3. адрес электронной почты (e-mail) Пользователя;</div>
      <div className={styles.docsSection__text}>3.2.4. страна места жительства/нахождения Пользователя;</div>
      <div className={styles.docsSection__text}>3.2.5. год рождения;</div>
      <div className={styles.docsSection__text}>3.2.6. специализация;</div>
      <div className={styles.docsSection__text}>3.3. Администрация платформы защищает данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы («пиксель»):</div>
      <ul className={styles.docsSection__list}>
        <li className={styles.docsSection__list__item}>IP адрес;</li>
        <li className={styles.docsSection__list__item}>информация из cookies;</li>
        <li className={styles.docsSection__list__item}>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li>
        <li className={styles.docsSection__list__item}>время доступа;</li>
        <li className={styles.docsSection__list__item}>адрес страницы, на которой расположен рекламный блок;</li>
        <li className={styles.docsSection__list__item}>реферер (адрес предыдущей страницы).</li>
      </ul>
      <div className={styles.docsSection__text}>3.3.1. Отключение cookies может повлечь невозможность доступа к платформе, требующим авторизации.</div>
      <div className={styles.docsSection__text}>3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</div>

      <h3 className={styles.docsSection__subtitle}>Цели сбора персональной информации пользователя</h3>
      <div className={styles.docsSection__text}>4.1. Персональные данные Пользователя Администрация платформы может использовать в целях:</div>
      <div className={styles.docsSection__text}>4.1.1. Идентификации зарегистрированного Пользователя для оформления заказа и (или) заключения Договора с ТОО.</div>
      <div className={styles.docsSection__text}>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам платформы.</div>
      <div className={styles.docsSection__text}>4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования платформы, оказания услуг, обработка запросов и заявок от Пользователя.</div>
      <div className={styles.docsSection__text}>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</div>
      <div className={styles.docsSection__text}>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</div>
      <div className={styles.docsSection__text}>4.1.6. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.</div>
      <div className={styles.docsSection__text}>4.1.7. Уведомления Пользователя о состоянии Заказа.</div>
      <div className={styles.docsSection__text}>4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа, определения права на получение кредитной линии Пользователем.</div>
      <div className={styles.docsSection__text}>4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием платформы.</div>
      <div className={styles.docsSection__text}>4.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени платформы или от имени партнеров платформы.</div>
      <div className={styles.docsSection__text}>4.1.11. Осуществления рекламной деятельности с согласия Пользователя.</div>
      <div className={styles.docsSection__text}>4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров платформы с целью получения обновлений и/или услуг.</div>

      <h3 className={styles.docsSection__subtitle}>Способы и сроки обработки персональной информации</h3>
      <div className={styles.docsSection__text}>5.1. Обработка персональных данных Пользователя осуществляется в срок, указанный в Согласии на обработку персональных данных, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</div>
      <div className={styles.docsSection__text}>5.2. Пользователь соглашается с тем, что Администрация платформы вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного в платформе.</div>
      <div className={styles.docsSection__text}>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Республики Казахстан только по основаниям и в порядке, установленным законодательством Республики Казахстан.</div>
      <div className={styles.docsSection__text}>5.4. При утрате или разглашении персональных данных Администрация платформы информирует Пользователя об утрате или разглашении персональных данных.</div>
      <div className={styles.docsSection__text}>5.5. Администрация платформы принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц, в частности:</div>
      <ul className={styles.docsSection__list}>
        <li className={styles.docsSection__list__item}>назначение ответственных лиц за организацию обработки персональных данных;</li>
        <li className={styles.docsSection__list__item}>осуществление внутреннего контроля и (или) аудита соответствия обработки персональных данных Закону Республики Казахстан от 21 мая 2013 года 94-V «О персональных данных и их защите» и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, локальным актам;</li>
        <li className={styles.docsSection__list__item}>ознакомление работников, непосредственно осуществляющих обработку персональных данных, с положениями законодательства Республики Казахстан о персональных данных, в том числе требованиями к защите персональных данных, локальными актами в отношении обработки персональных данных, и (или) обучением указанных работников.</li>
        <li className={styles.docsSection__list__item}>определение угроз безопасности персональных данных при их обработке в информационных системах персональных данных;</li>
        <li className={styles.docsSection__list__item}>оценка эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;</li>
        <li className={styles.docsSection__list__item}>учет машинных носителей персональных данных;</li>
        <li className={styles.docsSection__list__item}>обнаружение фактов несанкционированного доступа к персональным данным и принятием мер;</li>
        <li className={styles.docsSection__list__item}>восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
        <li className={styles.docsSection__list__item}>установление правил доступа к персональным данным, обрабатываемым в информационной системе персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в информационной системе персональных данных;</li>
        <li className={styles.docsSection__list__item}>контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности информационных систем персональных данных.</li>
      </ul>
      <div className={styles.docsSection__text}>5.6. Администрация платформы совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</div>

      <h3 className={styles.docsSection__subtitle}>Обязательства сторон</h3>
      <div className={styles.docsSection__text}>6.1. Пользователь обязан:</div>
      <div className={styles.docsSection__text}>6.1.1. Предоставить информацию о персональных данных, необходимую для использования платформы.</div>
      <div className={styles.docsSection__text}>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</div>
      <div className={styles.docsSection__text}>6.2. Администрация платформы обязана:</div>
      <div className={styles.docsSection__text}>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</div>
      <div className={styles.docsSection__text}>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.</div>
      <div className={styles.docsSection__text}>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</div>
      <div className={styles.docsSection__text}>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</div>

      <h3 className={styles.docsSection__subtitle}>Ответственность сторон</h3>
      <div className={styles.docsSection__text}>7.1. ТОО, не исполнившее свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Республики Казахстан, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.</div>
      <div className={styles.docsSection__text}>7.2. В случае утраты или разглашения Конфиденциальной информации ТОО не несёт ответственность, если данная конфиденциальная информация:</div>
      <div className={styles.docsSection__text}>7.2.1. Стала публичным достоянием до её утраты или разглашения.</div>
      <div className={styles.docsSection__text}>7.2.2. Была получена от третьей стороны до момента её получения ТОО.</div>
      <div className={styles.docsSection__text}>7.2.3. Была разглашена с согласия Пользователя.</div>

      <h3 className={styles.docsSection__subtitle}>Разрешение споров</h3>
      <div className={styles.docsSection__text}>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем платформы и ТОО, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</div>
      <div className={styles.docsSection__text}>8.2 Получатель претензии в течение пяти рабочих дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах ее рассмотрения.</div>
      <div className={styles.docsSection__text}>8.3. При не достижении соглашения спор будет передан на рассмотрение в суд по месту нахождения ТОО в соответствии с действующим законодательством Республики Казахстан.</div>

      <h3 className={styles.docsSection__subtitle}>Дополнительные условия</h3>
      <div className={styles.docsSection__text}>9.1. Изменения в настоящую Политику конфиденциальности вносятся без согласия Пользователя.</div>
      <div className={styles.docsSection__text}>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на портале, если иное не предусмотрено новой редакцией Политики конфиденциальности.</div>
      <div className={styles.docsSection__text}>9.3. Все предложения или вопросы по настоящей Политики конфиденциальности следует сообщать по адресу электронной почты: info@aimmagic.com</div>
    </section>
  )
}
